import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import tailwind from 'tailwind-rn';
import { transPonder, IMGs } from './components/Basics';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();
import { PlaneA as PlaneC } from './components/Graphing';

import {
  AppState, ImageBackground, StyleSheet, View, ScrollView, Button, SafeAreaView, Image,
  TouchableHighlight,
  Platform,
  Dimensions,
  Animated,Easing,
  TouchableOpacity,
  FlatList,
  TextInput as TextInput2,
  KeyboardAvoidingView,
  Linking,
  Clipboard, Text, LogBox, useWindowDimensions
} from 'react-native';
import {
  useFonts,
  Raleway_400Regular,
  Raleway_500Medium,
  Raleway_700Bold
} from '@expo-google-fonts/raleway';

import { TabView, SceneMap, TabBar } from 'react-native-tab-view';

function WhyUs (props) {
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      easing: Easing.bounce
    }).start();
  };
  useEffect(
    ()=>{
      //fadeIn();
    },[]
  )  
  return (
    <View style={{opacity: 1}}>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>Why cryptocurrencies?</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle}}>It is impossible to underestimate the speed of development of the cryptocurrencies asset class. What once was a risky way to gamble now represents a verified way to diversify your portfolio and earn calculated profit. Cryptocurrencies are becoming more and more common in today’s world, and they are changing the world around them. Technological progress is not only leading the markets but also creates new attractive ways to generate profits  together with experienced team of managers.</Text>
      </View>
    </View>

    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>Arbitrage strategy</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle}}>Blackfort offers you an opportunity to invest your money in the market-neutral strategy, which represents a more conservative way of investing into crypto assets. The managers invest into both short and long positions taking advantage of the asymmetry of the market while interest comes from the differences in spreads of various maturities as an example. For instance, short position in Bitcoin quarterly futures and long position in Bitcoin monthly futures provide an attractive opportunity to profit from arbitrage.</Text>
      </View>
    </View>

    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>Countertrend strategy</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle}}>The other way to invest your money represents a more offensive approach, a market-making strategy providing higher risk-reward. The managers tend to solve a markets optimization formula and create orders for cryptocurrencies. Sudden interventions into markets move the quotes and execute the orders. As a result, market inflow sells the position and market outflow buys the position, playing against the trend. The strategy allows for more market exposure but is not market neutral consequently.</Text>
      </View>
    </View>

    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>Why Blackfort Digital AMC?</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle}}>Blackfort Digital AMC combines both arbitrage and countertrend strategies with balanced weights to provide a smooth and high-yielding experience from investing into crypto assets. The unification of defensive and offensive approaches allows for controlled exposure to rapidly changing crypto markets for the diversification of your portfolio. Actively managed certificate represents a solid way to benefit from the market-leading technology and experience and expertise of professional investment team.</Text>
      </View>
    </View>

    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>About Blackfort Capital AG</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle}}>Blackfort Capital AG is a company duly registered under the laws of Switzerland and authorized by VQF SRO to provide investment management services to its clients. Blackfort Capital AG is responsible for the management of the Blackfort Digital AMC. </Text>
      </View>
    </View>

    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>Subscription</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle}}>For subscription and trading details please contact Blackfort Capital AG, Zurich.</Text>
      </View>
    </View>

    </View>
  )
}
function Performance (props) {
  
  const [actX, setActX] = useState(-1);
  const values = [2.62,1.59,2.94,2.95,2.38,1.31,4.17,19.52,-2.66,2.05,7.60,8.35,21.35,28.09,11.82,10.88,2.30,-3.03,0.47,4.21,2.24,1.23,6.37,-6.49,-7.38, 1.43];

  const months = ["Jan 2020", "Feb 2020", "Mar 2020", "Apr 2020", "May 2020", "Jun 2020", "Jul 2020", 'Aug 2020', "Sep 2020", "Oct 2020", "Nov 2020", "Dec 2020",
  "Jan 2021", "Feb 2021", "Mar 2021", "Apr 2021", "May 2021", "Jun 2021", "Jul 2021", 'Aug 2021', "Sep 2021", "Oct 2021", "Nov 2021", "Dec 2021", "Jan 2022", "Feb 2022"]
  return (
    <View style={{opacity: 1}}>
    { 1 ?
    <>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>Performance record</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle}}>Blackfort Digital AMC YTD return in 2021 was 79.43% and YTD return in 2020 was 52.83%.</Text>
      </View>
    </View>
    </> : null
    }

    <View style={{...tailwind(props.small ? 'w-full py-2 mb-4 ' : 'w-full py-2 mb-4 px-2')}}>
      <PlaneC setActX={setActX} actX={actX} />
      
      {actX !== -1 ? <View style={{backgroundColor:"#fff0da99",...tailwind('w-full flex flex-row flex-wrap items-center justify-center rounded-xl')}}>

        <View style={{...tailwind('p-2')}}>
          <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle,...tailwind('text-center')}}>P&L %</Text>
          <View style={{...tailwind('flex flex-row items-center justify-center')}}>
          <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-center px-2')}}>{months[actX]}</Text>
          <View style={{...tailwind('p-2 bg-white rounded-lg')}}>
          <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-center')}}>{values[actX]}</Text>
          </View>
          </View>
        </View>

      </View> : null }

      <View style={{backgroundColor:"#fff0da99",...tailwind('w-full flex flex-row flex-wrap items-center justify-center rounded-xl mt-4')}}>

        <View style={{...tailwind('p-2')}}>
          <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle,...tailwind('text-center')}}>Annual ROI</Text>
          <View style={{...tailwind('p-2 bg-white rounded-lg')}}>
          <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-center')}}>58.3%</Text>
          </View>
        </View>

        <View style={{...tailwind('p-2')}}>
          <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle,...tailwind('text-center')}}>Max. drawdown</Text>
          <View style={{...tailwind('p-2 bg-white rounded-lg')}}>
          <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-center')}}>26%</Text>
          </View>
        </View>

        <View style={{...tailwind('p-2')}}>
          <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle,...tailwind('text-center')}}>Return over max. drawdown</Text>
          <View style={{...tailwind('p-2 bg-white rounded-lg')}}>
          <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-center')}}>2.24</Text>
          </View>
        </View>

        <View style={{...tailwind('p-2')}}>
          <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle,...tailwind('text-center')}}>Total record</Text>
          <View style={{...tailwind('p-2 bg-white rounded-lg')}}>
          <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-center')}}>01.01.20 – 28.02.22</Text>
          </View>
        </View>

        <View style={{...tailwind('p-2')}}>
          <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle,...tailwind('text-center')}}>Profit reinvestment</Text>
          <View style={{...tailwind('p-2 bg-white rounded-lg')}}>
          <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-center')}}>Yes</Text>
          </View>
        </View>

        </View>
    </View>

    </View>
  )
}
function FactSheet (props) {

  const rownames = [
    'Issuer',
    'Paying Agent',
    'Strategy Manager',
    'Executing Broker',
    'Depositary & CryptoAsset Storage Provider',
    'Clearing Institution  / Clearing Code',
    'NAV Calculation',
    'ISIN',
    'Currency',
    'Structure',
    'Minimum Subscription',
    'Performance Fee',
    'Management Fee',
    'High Water Mark',
    'Secondary Market',
    'Manager Type',
    'Investment Process',
    'Status',
    'Domicile',
    'Issue Date',
    'Valuation Date',
    'Subscription Frequency',
    'Redemption Frequency',
    'Subscription Frequency',
    'Redemption Frequency'
    ];
  const rowvalues = [
    'iMAPS ETI AG',
    'ISP Securities AG',
    'Blackfort Capital AG',
    'Binance Exchange (Malta) & iCopperTechnologies (UK) Ltd',
    'Copper Technologies (UK) Ltd',
    'Issuance in Clearstream, available for settlement in Euroclear',
    'iMaps Capital Markets',
    'DE000A3GS102',
    'EUR',
    'Actively Managed Certificate',
    '1’000',
    '30%',
    '0.5%',
    'Yes',
    '1% bid-offer spread',
    'Single Manager',
    'Active',
    'Open-ended',
    'Liechtenstein',
    '26.08.21',
    'Weekly',
    'Weekly',
    'Weekly',
    'Weekly',
    'Weekly'
    ];
  return (
    <View style={{opacity: 1}}>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>FACTSHEET - Blackfort Digital AMC</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle}}>Blackfort Digital AMC offers investors access to an opportunity to benefit from modern rapidly changing cryptocurrency market. Blackfort Capital AG implements a strategy to grant you an easy and efficient way to profit from innovatively developing asset class.</Text>

      <View style={{...tailwind('w-full flex flex-row')}}>

        <View style={{...tailwind('flex flex-col mt-4 w-full')}}>

          {rownames.map((x,i)=><View style={{backgroundColor:(i+1) % 2?"#fff0da99":"white",...tailwind((i+1) % 2?'border-t border-b border-gray-300 p-2 flex flex-row':'p-2 flex flex-row')}}>
            <Text style={{fontFamily: 'Raleway_700Bold',...tailwind('text-right flex-1 px-2')}}>{x}</Text>
            <Text style={{fontFamily: 'Raleway_400Regular',...tailwind('text-left flex-1 px-2')}}>{rowvalues[i]}</Text>
          </View>)}



        </View>

      </View>

      <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('mt-4')}}>The investment objective of the strategy is to achieve long-term portfolio growth by applying statistical arbitrage algorithmic trading strategies and profiting from cryptocurrency market inefficiencies. Such approach is market-neutral and allows to profit from market volatility rather than a flat, rising or falling market. The range of markets and instruments to which algorithmic strategies are applied is constantly revised in order to ensure that the strategies are executed on markets and instruments with the best arbitrage opportunities.</Text>

      </View>
    </View>

    
    </View>
  )
}
function Disclosures (props) {
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      easing: Easing.bounce
    }).start();
  };
  useEffect(
    ()=>{
      //fadeIn();
    },[]
  )  
  return (
    <View style={{opacity: 1}}>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-start justify-center pl-4 pr-4 py-2 ' : 'w-full flex flex-row items-start justify-center pl-8 pr-4 py-2 ')}}>
    <Image source={IMGs.bullet} style={{...tailwind(props.small ? 'h-4 w-4': 'h-6 w-6')}} />
      <View style={{...tailwind(props.small ? 'flex-1 px-2' : 'flex-1 px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...props.textStyle}}>DISCLAIMER for clients</Text>
      </View>
    </View>
    <View style={{...tailwind(props.small ? 'w-full flex flex-row items-center justify-center px-4 py-2 mb-4 ' : 'w-full flex flex-row items-center justify-center px-8 py-2 mb-4 ')}}>
      <View style={{...tailwind(props.small ? 'w-4 h-full mr-4 flex flex-row items-center justify-center' : 'w-6 h-full mr-4 flex flex-row items-center justify-center')}}>
        <View style={{...tailwind('w-1 bg-gray-200 h-full')}}></View>
      </View>
      <View style={{...tailwind('flex-1')}}>
        <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-sm')}}>This document has been issued by Blackfort Capital or its affiliates / associates (hereinafter "Blackfort") for informational purposes only and under no circumstances should it be construed as an offer to sell or a solicitation of any offer to buy any offers - either securities or other financial instruments. This document is not an invitation to provide any services, advertising or an offer to conclude any deals or agreements. All materials are intended for use in this document, are indicative and intended for your use only. This document does not address investment goals, financial situation, or individual needs. The provision of information in this post is not based on your circumstances. This is not investment advice and Blackfort does not make any recommendations regarding any products or transactions referred to in this document, nor does it claim to identify all risks (directly or indirectly) associated with any product or transaction described in this document.</Text>


      <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-sm mt-4')}}>Blackfort and / or related parties may take a position, participate in the market and / or transact in any investment or related investment mentioned here and provide financial services to issuers of any other person mentioned here. The information contained in this document is based on materials and sources that we believe are reliable, however Blackfort makes no implied liability as to the accuracy, completeness or reliability of the information contained in this document. Any prices or levels shown in this document are indicative and preliminary. Opinions are expressed as of the date of publication in this material. Any opinions expressed are subject to change without notice and Blackfort is under no obligation to update the information contained herein. Neither Blackfort nor its agents, representatives, agents or employees shall be liable in any way for any consequential loss or damage arising out of any use of this document.</Text>

      <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-sm mt-4')}}>Factual statements, statistics, information about actual and perceived issues presented in this document, opinions expressed, and predictions or statements regarding various issues mentioned in this document do not necessarily represent an assessment or interpretation of Blackfort's information. You should not treat the content of this document as advice on legal, tax, or investment issues. You must determine how you should rely on any data, statements or opinions contained in this document, and Blackfort is responsible for them. It is recommended that you conduct your own due diligence.</Text>

      <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind('text-sm mt-4')}}>This document should not be used by any citizen or other jurisdiction in any place, state, country, or other jurisdiction where such distribution or use would be contrary to laws or regulations. This document is proprietary and proprietary. It is exclusively for use by the designated addressees. No unauthorized use is allowed, including copying, disclosure, storage, distribution, or transmission of materials. This document should not be construed as investment, tax, legal, accounting, regulatory or other advice, or as the creation of fiduciary relationships.</Text>

      </View>

      

    </View>

    <Text style={{fontFamily: 'Raleway_400Regular',...props.textStyle,...tailwind(props.small ?'text-sm mt-4 text-right pl-4 pr-4 py-2':'text-sm mt-4 text-right pl-4 pr-8 py-2')}}>© Blackfort Capital AG. All rights reserved.</Text>

    
    </View>
  )
}
function TabsMenu (props) {
  const inputRange = props.navigationState.routes.map((x, i) => i);
  const [subIndex, setSubIndex] = useState(-1);

  return (
    <View style={{...tailwind('bg-white flex flex-row overflow-hidden')}}>
      {props.navigationState.routes.map((route, i) => {
        return (
          <View style={{backgroundColor:i===subIndex ? "#f7a81d33":"#f7a81d11",...tailwind(i===subIndex ? 'flex flex-col flex-1' : 'flex flex-col flex-1')}}>
          <TouchableOpacity activeOpacity={0.7}
            style={{...tailwind('flex-1 p-2 flex flex-row items-center justify-center')}}
            onPress={() => props.setIndex(i)}
            onMouseOver={()=>{setSubIndex(i)}}
            onMouseOut={()=>{setSubIndex(-1)}}
            >
            <Text style={{ fontFamily: props.index===i?'Raleway_700Bold':'Raleway_400Regular', color: "black", ...tailwind('text-base') }}>{route.title}</Text>
          </TouchableOpacity>
          {1 ? <View style={{height:5,backgroundColor:props.index===i?"#f7a81d":"transparent",...tailwind('')}}></View> : null }
          </View>
        );
      })}
    </View>
  );
};
function TabsViews (props) {

  const [index, setIndex] = useState(1);

  const layout = useWindowDimensions();
  //const layout = {width: 1000};

  const FirstRoute = () => {
    return (
    <View style={{width: "100%",...tailwind('bg-white bg-opacity-90 w-full rounded-b-xl ')}}>
      <View style={{...tailwind(layout.width > 1200 ? 'rounded-b-xl w-full p-4' : 'rounded-b-xl w-full p-1')}}>
        <WhyUs small={layout.width <= 1200} textStyle={{...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}/>
      </View>
    </View>
    )
  };

  const SecondRoute = () => {
    return (
    <View style={{width: "100%",...tailwind('bg-white bg-opacity-90 w-full rounded-b-xl ')}}>
      <View style={{...tailwind(layout.width > 1200 ? 'rounded-b-xl w-full p-4' : 'rounded-b-xl w-full p-1')}}>
        <Performance small={layout.width <= 1200} textStyle={{...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}/>
      </View>
    </View>
    )
  };

  const ThirdRoute = () => {
    return (
    <View style={{width: "100%",...tailwind('bg-white bg-opacity-90 w-full rounded-b-xl ')}}>
      <View style={{...tailwind(layout.width > 1200 ? 'rounded-b-xl w-full p-4' : 'rounded-b-xl w-full p-1')}}>
        <FactSheet small={layout.width <= 1200} textStyle={{...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}/>
      </View>
    </View>
    )
  };

  const FourthRoute = () => {
    return (
    <View style={{width: "100%",...tailwind('bg-white bg-opacity-90 w-full rounded-b-xl ')}}>
      <View style={{...tailwind(layout.width > 1200 ? 'rounded-b-xl w-full p-4' : 'rounded-b-xl w-full p-1')}}>
        <Disclosures small={layout.width <= 1200} textStyle={{...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}/>
      </View>
    </View>
    )
  };
  
  const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
    third: ThirdRoute,
    fourth: FourthRoute,
  });

  const [routes] = useState([
    { key: 'first', title: 'Why us' },
    { key: 'second', title: 'Performance Report' },
    { key: 'third', title: 'Factsheet' },
    { key: 'fourth', title: 'Disclosures' },
  ]);

  return (
    <>
    <Image source={IMGs.background} style={{width: '100%', height: '100%',...tailwind('absolute w-full h-full p-0')}} />
    <View style={{maxWidth:1200,...tailwind(layout.width > 1200 ? 'px-8 py-2' : 'p-0')}} onLayout={({ nativeEvent: event }) => {
      //const { lay } = event;
      //setLayout(lay);
    }}>
    <View style={{...tailwind(layout.width > 1200 ? 'flex flex-col rounded-xl overflow-hidden p-4 bg-white bg-opacity-50' : 'flex flex-col rounded-xl overflow-hidden p-2 bg-white bg-opacity-50')}}>
    <View style={{...tailwind(layout.width > 1200 ? 'flex flex-col rounded-xl overflow-hidden' : 'border-black border-opacity-30 flex flex-col rounded-xl overflow-hidden')}}>
      <View style={{borderTopWidth:5,borderLeftWidth:5,borderRightWidth:5,...tailwind('border-black border-opacity-50 bg-white bg-opacity-90 rounded-t-xl overflow-hidden')}}>
        <View style={{...tailwind('w-full px-4')}}>
        <Text style={{fontFamily: 'Raleway_700Bold',...tailwind('text-4xl text-center font-bold w-full pt-4 pb-2 border-b border-gray-200')}}>Blackfort Digital AMC</Text>
        <Text style={{fontFamily: 'Raleway_400Regular',...tailwind('text-lg text-center w-full pb-4 pt-2')}}>Benefiting from dynamically changing modern currencies</Text>
        </View>
      </View>
      <View style={{borderBottomWidth:5,borderLeftWidth:5,borderRightWidth:5,...tailwind('w-full border-black border-opacity-50 rounded-b-xl bg-white bg-opacity-90')}}>
      <TabView
        navigationState={{ index, routes }}
        renderTabBar={(props)=>TabsMenu({...props, ...{index: index, setIndex: setIndex}})}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width > 1200 ? 1200 : layout.width }}
      />
      </View>
    </View>
    </View>
    </View>
    </>
  );

};

function Disclaimer (props) {
  const layout = useWindowDimensions();
  return (
    <>
    <Image source={IMGs.background} style={{width: '100%', height: '100%',...tailwind('absolute w-full h-full p-0')}} />
    <View style={{...tailwind(layout.width > 1200 ? 'p-4 h-full w-full flex flex-col' : 'p-0 h-full w-full flex flex-col')}}>
      <View style={{...tailwind(layout.width > 1200 ? 'w-full flex-1 bg-black bg-opacity-30 rounded-xl flex flex-col items-center justify-center p-8' : 'w-full flex-1 bg-black bg-opacity-30 rounded-xl flex flex-col items-center justify-center p-2')}}>
        <View style={{maxWidth:1200,...tailwind(layout.width > 1200 ? 'w-full flex-1 p-8 flex flex-col bg-white bg-opacity-100 rounded-xl items-center justify-start' : 'w-full flex-1 p-4 flex flex-col bg-white bg-opacity-100 rounded-xl items-center justify-start')}}>
          <Text style={{fontFamily: 'Raleway_700Bold',...tailwind('text-4xl text-center font-bold border-b border-gray-200 w-full')}}>Blackfort Capital Disclaimer</Text>
          <ScrollView style={{...tailwind('w-full flex-1 bg-white mt-4')}}>
          <View style={{...tailwind('w-full flex flex-row items-center justify-center px-8 py-4 mb-4')}}>
            <View style={{...tailwind('w-1 bg-gray-200 h-full mr-4')}}></View>
            <View style={{...tailwind('flex-1')}}>
              <Text style={{fontFamily: 'Raleway_400Regular',...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-base text-justify w-full')}}>In order to view the information relating to the <Text style={{fontFamily: 'Raleway_700Bold',...tailwind('')}}>Blackfort Digital AMC</Text>, you must accept the terms and conditions set out below, and our separate Terms of use. By clicking the button "I accept" below and accessing the information and material available on the Website, you accept these Terms.</Text>
              <Text style={{fontFamily: 'Raleway_400Regular',...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full mt-4' : 'text-base text-justify w-full mt-4')}}>The website and the information contained herein is not intended to be a source of investment advice or credit analysis with respect to the material and/or presented. The material presented to you in this website is not and should not be considered as an offer, invitation or recommendation to sell or a solicitation of an offer to buy any of the securities in any jurisdiction. The information provided does not take into account your particular investment objectives, financial situation or needs. Before acting on any information you should consider the appropriateness of the information having regard to these matters.</Text>
            </View>
          </View>
          <Text style={{fontFamily: 'Raleway_500Medium',...tailwind('text-lg font-bold text-justify w-full px-8 mb-4')}}>I confirm that:</Text>
          <View style={{...tailwind('w-full flex flex-row items-start justify-center pl-8 pr-4 py-2')}}>
            <Image source={IMGs.bullet} style={{...tailwind('h-6 w-6')}}  />
            <View style={{...tailwind('flex-1 px-4')}}>
              <Text style={{fontFamily: 'Raleway_500Medium',...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}>I have read and accept the Terms and Conditions of using this website, detailed above;</Text>
            </View>
          </View>
          <View style={{...tailwind('w-full flex flex-row items-start justify-center pl-8 pr-4 py-2')}}>
            <Image source={IMGs.bullet} style={{...tailwind('h-6 w-6')}}  />
            <View style={{...tailwind('flex-1 px-4')}}>
              <Text style={{fontFamily: 'Raleway_500Medium',...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}>I am a sophisticated investor;</Text>
            </View>
          </View>
          <View style={{...tailwind('w-full flex flex-row items-start justify-center pl-8 pr-4 py-2')}}>
            <Image source={IMGs.bullet} style={{...tailwind('h-6 w-6')}}  />
            <View style={{...tailwind('flex-1 px-4')}}>
              <Text style={{fontFamily: 'Raleway_500Medium',...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}>I will not make an investment in an investment Product, or advise another to do so, if the promotion of the investment Product to the type of investor I represent is prohibited;</Text>
            </View>
          </View>
          <View style={{...tailwind('w-full flex flex-row items-start justify-center pl-8 pr-4 py-2')}}>
            <Image source={IMGs.bullet} style={{...tailwind('h-6 w-6')}}  />
            <View style={{...tailwind('flex-1 px-4')}}>
              <Text style={{fontFamily: 'Raleway_500Medium',...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}>I recognise that the information provided on this website is for information purposes only and is not a solicitation of an investment;</Text>
            </View>
          </View>
          <View style={{...tailwind('w-full flex flex-row items-start justify-center pl-8 pr-4 py-2')}}>
            <Image source={IMGs.bullet} style={{...tailwind('h-6 w-6')}}  />
            <View style={{...tailwind('flex-1 px-4')}}>
              <Text style={{fontFamily: 'Raleway_500Medium',...tailwind(layout.width > 1200 ? 'text-lg text-justify w-full' : 'text-sm text-justify w-full')}}>I will study the prospectus, KIID or Offering Memorandum of an Investment Product, including the risk factors before making an investment.</Text>
            </View>
          </View>
          <View style={{...tailwind('flex flex-row items-center justify-end pt-4 px-8')}}>
          <TouchableOpacity onPress={()=>{
            props.navigation.navigate("Blackfort Digital AMC", {})
              //  'portfolio': data['children'].slice(),
              //  'transactions': data['transactions'].slice(),
              //  'lang': props.lang,
              //  'ui': props.ui,
              //  'encrypted': data['encrypted']});
          }} style={{backgroundColor:"#ffc107",...tailwind('px-4 py-2 rounded-xl')}}>
            <Text style={{fontFamily: 'Raleway_500Medium',...tailwind('text-lg font-bold text-justify w-full')}}>I accept</Text>
          </TouchableOpacity>
          </View>
          <View style={{...tailwind('h-4')}}></View>
          </ScrollView>
          
        </View>
      </View>
    </View>
    </>
  )
}

export default function App (props) {
  let [fontsLoaded] = useFonts({
    Raleway_400Regular,
    Raleway_500Medium,
    Raleway_700Bold
  });
  const get_dictionary = async () => {
    let result = await transPonder(1, "getDictionary", {});
    //alert(JSON.stringify(result));
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('mmm');
    //alert(myParam);
  }
  useEffect(
    ()=>{
      get_dictionary();
    },
    []
  )
  return (
    !fontsLoaded ? null :
    <NavigationContainer>
      {// disclosures screen
        <>
        <Stack.Navigator screenOptions={{
          headerShown: false,
          animationEnabled: true
        }}>
          {
          //<Stack.Screen name={'Blackfort Digital AMC: Disclosures'}>
          //  {props => <Disclaimer {...props} /> }
          //</Stack.Screen>
          }
          <Stack.Screen name={'Blackfort Digital AMC'}>
            {props => <TabsViews {...props} /> }
          </Stack.Screen>
        </Stack.Navigator>
        </>
      }
    </NavigationContainer>
  )
}