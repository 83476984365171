import React, { useState, useEffect, useRef } from 'react';
import tailwind from 'tailwind-rn';
import { TabBarIOS } from 'react-native';
import Svg, { Path, G, Text, Rect, Circle } from "react-native-svg";
import {
  AppState, ImageBackground, StyleSheet, View, ScrollView, Button, SafeAreaView, Image,
  TouchableHighlight,
  Platform,
  Dimensions,
  Animated,
  TouchableOpacity,
  FlatList,
  TextInput as TextInput2,
  KeyboardAvoidingView,
  Linking,
  Clipboard, LogBox
} from 'react-native';

export function PlaneA (props) {
  const xAxisLeft = 50;
  const xStep = 37;
  const [actX, setActX_internal] = useState(props.actX);
  const setActX = (v) => {
    setActX_internal(v);
    props.setActX(v);
  }
  const values = [2.6, 4.2, 7.2,10.1,12.5,13.8,18.0,37.5,34.8,36.9,44.5,52.8,74.2,102.3,114.1,125,127.3,124.2,124.7,128.9,131.2,132.4,138.8,132.3, 124.9, 126.3];
  const months = ["Jan 2020", "Feb 2020", "Mar 2020", "Apr 2020", "May 2020", "Jun 2020", "Jul 2020", 'Aug 2020', "Sep 2020", "Oct 2020", "Nov 2020", "Dec 2020",
  "Jan 2021", "Feb 2021", "Mar 2021", "Apr 2021", "May 2021", "Jun 2021", "Jul 2021", 'Aug 2021', "Sep 2021", "Oct 2021", "Nov 2021", "Dec 2021", "Jan 2022", "Feb 2022"]
  return (
    <View style={{...tailwind('w-full')}}>
    <View style={{
      position: "relative",
      paddingBottom:"60%",
      height:"0",
      width:"100%",
      backgroundColor:"transparent"}}>
    <Svg style={{...tailwind('absolute top-0 left-0 w-full')}} viewBox={"0 0 1000 600"} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G fontFamily='Raleway_500Medium' pointerEvents="visible">

        <Rect x="10" y="0" width="980" height="590" fill="transparent" pointerEvents="visible" onMouseOver={()=>{setActX(-1)}} />

        <Path d="M 45 10 L 55 10 L 50 0 z" fill="#999999" />
        <Path d={"m "+xAxisLeft+",10 0,480"} fill="none" stroke="#999999" />
        <Path d={"m "+xAxisLeft+",490 930,0"} fill="none" stroke="#999999" />
        
        <Text x={xAxisLeft-7} y={344} text-anchor="end">50</Text>
        <Path d={"m "+xAxisLeft+",340 930,0"} fill="none" stroke="#999999" stroke-dasharray="5" />
        
        <Text x={xAxisLeft-7} y={194} text-anchor="end">100</Text>
        <Path d={"m "+xAxisLeft+",190 930,0"} fill="none" stroke="#999999" stroke-dasharray="5" />
        
        <Text x={xAxisLeft-7} y={44} text-anchor="end">150</Text>
        <Path d={"m "+xAxisLeft+",40 930,0"} fill="none" stroke="#999999" stroke-dasharray="5" />

        <Path d="m 10,10 0,480 980,0 " fill="none" stroke="none" />

        {values.map((x,i)=>i?
        <><Path d={"m "+String(10+i*xStep)+","+String(480-3*(values[i-1]-values[0]))+" "+String(xStep)+","+String(-3*(values[i]-values[i-1]))}
          fill="none"
          stroke={actX===i?"#f7a81d":"black"}
          stroke-width="4"
           />
        </>
        :null)}

        <Text x={String(20+(0+1)*xStep)} y={465} r="5" fill={actX===0?"#f7a81d":"#9999cc"} text-anchor="end">{values[0]}</Text>
        <Path d={"m "+String(20+(0+1)*xStep)+",480"+" 0,-15"}
          fill="none"
          stroke={actX===0?"#f7a81d":"none"}
          stroke-width="2"
           />
        {values.map((x,i)=>i?
        <>
          <Text x={String(20+(i+1)*xStep)} y={String(465-3*(values[i]-values[0]))} r="5" fill={actX===i?"#f7a81d":"#999999cc"} text-anchor="end">{values[i]}</Text>
          <Path d={"m "+String(10+(i+1)*xStep)+","+String(480-3*(values[i]-values[0]))+" 0,-15"}
          fill="none"
          stroke={actX===i?"#f7a81d":"none"}
          stroke-width="2"
           />
        </>
        :null)}

        <Circle cx={String(10+(0+1)*xStep)} cy={480} r="5" stroke="black" fill={actX===0?"#f7a81d":"white"} />
        {values.map((x,i)=>i?
        <>
          <Circle cx={String(10+(i+1)*xStep)} cy={String(480-3*(values[i]-values[0]))} r="5" stroke="black" fill={actX===i?"#f7a81d":"white"} />
        </>
        :null)}

        {values.map((x,i)=>i?
        <>
        <Path d={"m "+String(10+(i)*xStep)+",10 "+String(xStep+3)+",0"} fill="none" stroke={actX===i?"#f7a81d":"none"} stroke-width="2" />
        <Rect x={String(10+(i)*xStep)} y={10}  width={xStep+3} height="480" fill={actX===i?"#99999911":"transparent"} pointerEvents="visible" onMouseOver={()=>{setActX(i)}} />
        </>
        :null)}

        <G transform={"rotate(-55,"+String(10+(0+1)*xStep)+",520)"}>
          <Text x={50} y={520} fill="#999999" text-anchor="end">Jan 2020</Text>
        </G>
        {months.map((x,i)=>i?
        <G transform={"rotate(-55,"+String(10+(i+1)*xStep)+",520)"}>
          <Text x={String(10+(i+1)*xStep)} y={520} fill={actX===i?"#f7a81d":"#999999cc"} text-anchor="end">{x}</Text>
        </G>
        :null)}

      </G>
    </Svg>
    </View>
    </View>
  )
}