// elements
import React, { useEffect, useState, useRef } from 'react';
import { AppState, View, SafeAreaView, ImageBackground, ScrollView, TouchableOpacity, Text, Platform } from 'react-native';
import { BlurView } from 'expo-blur';
import tailwind from 'tailwind-rn';


// constants
export const ARTEMLINK = "https://ccifxneahe.execute-api.eu-central-1.amazonaws.com/org_blackfort_landfst/feeder"
//export const WSSLINK = "wss://w7jf59y6da.execute-api.eu-central-1.amazonaws.com/org_finupwh_stage"

export const IMGs = {
  background: require('../../assets/zurich.jpeg'),
  bullet: require('../../assets/bullet.png')
};
//export const COLORs = {
//  main: '#79bbde',
//  main2: '#79bbde',
//}

export const transPonder = async (auth, code, data) => {

  // returns data in json format
  // or returns "authdrop" string
  // or returns "otherproblem" string

  try {

    let data_send = {...data};
    //if (auth) {
    //  // authenticate
    //  const json_credentials = await authMe();
    //  if (json_credentials === 'authdrop') {
    //    return "authdrop"
    //  }
    //  data_send = {...data_send, ...{
    //    "email": json_credentials.email,
    //    "token": json_credentials.token
    //  }}
    //}

    // send data with complete flag
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({...{"action": code}, ...data_send})
    };
  
    const outcome = await fetch(ARTEMLINK, requestOptions);
    const outcome_json = await outcome.json();

    return outcome_json
  
  } catch (e) {
    return "otherproblem"
  }

}